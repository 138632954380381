import { Pivot, PivotItem } from "@fluentui/react";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, MouseEvent, useCallback } from "react";
import { t } from "i18next";
import { useUserRole } from "contexts/UserRoleContext";
import { TabQuestionsAnswers } from "./HelpCenterTabs/TabQuestionsAnswers";
import { TabContactSupport } from "./HelpCenterTabs/TabContactSupport";
import { IQACategorySourceData, IQAContent, IQAContentCategory } from "../../pages/HelpCenter";

export interface IHelpCenterProps {
  groupedQaContent: any;
  qaItemOpened: number | undefined;
  qaItemFullDisplayOpened: number | undefined;
  searchString: string | undefined;

  setQaItemOpened(data: number | undefined): void;

  setQaItemFullDisplayOpened(data: number | undefined): void;

  setSearchString(data: string | undefined): void;
}

export const HelpCenterTabs = (props: IHelpCenterProps) => {
  const { userRole } = useUserRole();
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.hash.substring(1) || "questions-answers";
  const groupedQaContent = props.groupedQaContent;

  const onTabClick = (item?: PivotItem, ev?: MouseEvent<HTMLElement>) => {
    if (item) {

      //this needs proper implementation later. Just hacking here to make Videos a link instead of a tab
      if (item.props.itemKey === "videos") {
        window.open("https://www.deliwi.ai/deliwiai-launch-and-usage-guide", '_blank')?.focus(); //kovakoodattu koska miksei:D
      }
      else {
        const newActiveTab = item.props.itemKey;
        const newPath = `${location.pathname}#${newActiveTab}`;
        navigate(newPath);
      }
    }


  };

  const helpCenterTabs = [
    {
      headerText: t("HelpCenter.Tab.QuestionsAnswers"),
      className: "help-center__tabs--tabcontent",
      tabComponent: <TabQuestionsAnswers groupedContent={groupedQaContent} qaItemOpened={props.qaItemOpened} setQaItemOpened={props.setQaItemOpened} qaItemFullDisplayOpened={props.qaItemFullDisplayOpened} setQaItemFullDisplayOpened={props.setQaItemFullDisplayOpened} searchString={props.searchString} setSearchString={props.setSearchString} />,
      roles: ["user", "superuser", "admin"],
      key: "questions-answers",
    },
    {
      headerText: t("HelpCenter.Tab.ContactSupport"),
      className: "admin-page__tabs--tabcontent",
      tabComponent: <TabContactSupport />,
      roles: ["user", "superuser", "admin"],
      key: "contact-support",
    },
    {
      headerText: t("HelpCenter.Tab.Videos"),
      className: "admin-page__tabs--tabcontent",
      tabComponent: <></>,
      roles: ["user", "superuser", "admin"],
      key: "videos",
    }
  ];

  const isRoleAllowed = (roles: string[]) => roles.some((role: string) => role === "any" || userRole.includes(role));

  return (
    <Pivot className="help-center-page__tabs" selectedKey={currentTab} onLinkClick={onTabClick}>
      {helpCenterTabs
        .filter((tab) => isRoleAllowed(tab.roles))
        .map((tab, index) => (
          <PivotItem headerText={tab.headerText} className={tab.className} itemKey={tab.key} key={index} alwaysRender={true}>
            {tab.tabComponent}
          </PivotItem>
        ))}
    </Pivot>
  );
};
